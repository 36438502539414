@import "./config";

html {
  font-size: 16px;
  line-height: 20px;
}

html,
body {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

nav {
  margin-right: 70px;

  ul {
    margin: 0 16px;
    padding: 0;
    display: flex;
  }

  li {
    display: block;
  }

  a {
    display: block;
    padding: 4px 8px;
    outline: none;

    text-decoration: none;

    color: black;

    &:hover {
      background: #eee;
    }

    &.active {
      background: #333;
      color: white;
    }
  }
}

.container {
  max-width: 1200px;
  margin: 8px 16px;

  overflow: hidden;
}

h1 {
  margin: 0 0 8px 0;
  line-height: 40px;
}

ul,
li,
p {
  margin: 0 0 16px 0;
}

ul {
  padding-left: 32px;
}
