@import "../config";

.tutorial {
  width: 1000px;
}

pre.sql {
  margin: 0 0 16px 0;
  font-family: Menlo, monospace;
  font-size: 14px;

  .highlight {
    font-weight: bold;
  }
}

button.step {
  display: inline-block;
  background: white;
  border: 2px solid #eee;
  margin: 0 2px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-family: serif;

  &:hover {
    border-color: #aaa;
  }

  &.active {
    border-color: black;
    font-weight: bold;
  }

  &:focus {
    background: #eee;
  }
}

.explanation {
  display: flex;

  .col {
    width: 50%;

    &.left {
      padding-right: 8px;
    }
    &.right {
      padding-left: 8px;
    }
  }

  .text {
    font-size: 14px;
    line-height: 16px;
  }
}

.error {
  color: $red;
  font-weight: bold;
  font-style: italic;
}

.viz {
  margin: 0 0 16px 0;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

span {
  transition: opacity 0.2s ease;
}

.excluded {
  opacity: 0.2;
}

.join {
  display: flex;
  position: relative;

  transition: opacity 0.2s ease, margin 0.2s ease, padding 0.2s ease,
    border 0.2s ease;

  .data & {
    border: 0 solid transparent;
    border-radius: 8px;
  }

  .grouped-enter .data &,
  .grouped-enter-done .data & {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    padding: (6px - 4px) 6px 6px 6px;
    border: 2px dashed #666;
    background: #f5f5f5;
  }

  .right {
    margin-left: 4px;

    opacity: 0;
    position: relative;
    left: 20px;

    transition: left 0.2s ease, opacity 0.2s ease;

    // .joined-appear & {
    //   opacity: 1;
    //   left: 0;
    //   transition: none;
    // }

    .joined-enter &,
    .joined-enter-done & {
      opacity: 1;
      left: 0;
    }
  }
}

.mark {
  position: absolute;
  z-index: 12;
  top: -1px;
  left: -1px;
  bottom: -1px;
  background: transparent;
  border: 2px solid transparent;
  transition: border-color 0.2s ease, background-color 0.2s ease;
}
.mark-enter,
.mark-enter-done,
.mark-appear,
.mark-appear-done {
  width: 100%;
  width: calc(100% + 2px);

  &,
  &.blue {
    background: rgba(#16b1ee, 0.4);
    border-color: #16b1ee;
  }

  &.color-id-1 {
    background: rgba(#1b46d4, 0.4);
    border-color: #1b46d4;
  }

  &.color-id-2 {
    background: rgba(#16cf5d, 0.4);
    border-color: #16cf5d;
  }

  &.color-id-4 {
    background: rgba(#ca16ee, 0.4);
    border-color: #ca16ee;
  }

  &.red {
    background: rgba($red, 0.4);
    border-color: $red;
  }
}
.mark-exit,
.mark-exit-done {
  transition: none;
}

.excluded .right {
  display: none;
}

.data .left .row:nth-child(n + 2) {
  opacity: 0;
  position: relative;
  top: -10px;

  transition: top 0.2s ease, opacity 0.2s ease;

  // .joined-appear & {
  //   opacity: 1;
  //   top: 0;
  //   transition: none;
  // }

  .joined-enter &,
  .joined-enter-done & {
    opacity: 1;
    top: 0;
  }
}

.row {
  margin-top: 4px;
  display: flex;
  height: 32px;
  border: 1px solid transparent;
  position: relative;
  background: white;

  .data & {
    border-color: #333;
  }

  .excluded & {
    border-style: dashed;
  }

  .header & {
    font-weight: bold;
  }
}

.cell {
  padding: 4px 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  position: relative;
  z-index: 10;

  .data &:not(:last-child) {
    border-right: 1px solid #ddd;
  }
}

.null {
  font-family: Menlo, monospace;
  font-size: 0.7rem;
}
